<template>
    <ValidationObserver v-slot="{ invalid }">
        <div class="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
            <transition
                enter-active-class="duration-300 ease-out"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 opacity-75 bg-neutral-800"></div>
                </div>
            </transition>

            <transition
                enter-active-class="duration-300 ease-out"
                enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
                enter-to-class="translate-y-0 opacity-100 sm:scale-100"
                leave-active-class="duration-200 ease-in"
                leave-class="translate-y-0 opacity-100 sm:scale-100"
                leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            >
                <div class="overflow-hidden transition-all transform bg-white rounded-lg shadow-xl" :class="width">
                    <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start">
                            <slot name="icon" />
                            <div class="w-full mt-3 text-center sm:mt-0 sm:text-left">
                                <div class="text-xl font-medium text-neutral-700">
                                    {{ title }}
                                </div>
                                <div class="flex flex-col items-baseline mt-2 space-y-4 border-t border-neutral-300">
                                    <div class="w-full mt-4">
                                        <slot />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between">
                        <span class="flex items-center w-full px-6 mb-3 rounded-md sm:mt-0 sm:w-auto">
                            <slot name="loader"></slot>
                        </span>
                        <div class="px-4 py-2 bg-neutral-50 sm:pb-6 sm:px-6 sm:flex sm:flex-row-reverse">
                            <span v-if="!showCloseButton" class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                                <button
                                    type="button"
                                    :disabled="invalid || !allowSave || loading"
                                    class="
                                        inline-flex
                                        justify-center
                                        w-full
                                        px-4
                                        py-2
                                        text-base
                                        font-medium
                                        leading-6
                                        text-white
                                        transition
                                        duration-150
                                        ease-in-out
                                        border border-transparent
                                        rounded-md
                                        shadow-sm
                                        focus:outline-none
                                        sm:text-sm sm:leading-5
                                        bg-primary-600
                                        hover:bg-primary-500
                                        focus:border-primary-700 focus:ring-primary
                                        disabled:cursor-not-allowed disabled:bg-primary-400
                                    "
                                    @click.stop="$emit('save')"
                                >
                                    {{ saveButtonText }}
                                </button>
                            </span>
                            <span
                                v-if="!showCloseButton"
                                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
                            >
                                <button
                                    :disabled="loading"
                                    type="button"
                                    class="
                                        inline-flex
                                        justify-center
                                        w-full
                                        px-4
                                        py-2
                                        text-base
                                        font-medium
                                        leading-6
                                        transition
                                        duration-150
                                        ease-in-out
                                        bg-white
                                        border
                                        rounded-md
                                        shadow-sm
                                        border-neutral-300
                                        text-neutral-700
                                        hover:text-neutral-500
                                        focus:outline-none focus:border-blue-300 focus:ring
                                        sm:text-sm sm:leading-5
                                        disabled:cursor-not-allowed disabled:text-neutral-500
                                    "
                                    @click.stop="$emit('cancel')"
                                >
                                    {{ cancelButtonText }}
                                </button>
                            </span>
                            <span
                                v-if="showCloseButton"
                                class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
                            >
                                <button
                                    :disabled="loading"
                                    type="button"
                                    class="
                                        inline-flex
                                        justify-center
                                        w-full
                                        px-4
                                        py-2
                                        text-base
                                        font-medium
                                        leading-6
                                        transition
                                        duration-150
                                        ease-in-out
                                        bg-white
                                        border
                                        rounded-md
                                        shadow-sm
                                        border-neutral-300
                                        text-neutral-700
                                        hover:text-neutral-500
                                        focus:outline-none focus:border-blue-300 focus:ring
                                        sm:text-sm sm:leading-5
                                    "
                                    @click.stop="$emit('close')"
                                >
                                    Close
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </ValidationObserver>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

export default defineComponent({
    name: 'FormModal',
    components: { ValidationObserver },
    props: {
        title: {
            type: String,
            required: true,
        },
        saveButtonText: {
            type: String,
            default: 'Save',
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel',
        },
        width: {
            type: String,
            default: 'min-w-1/3 sm:max-w-lg sm:w-full',
        },
        allowSave: {
            type: Boolean,
            default: true,
        },
        showCloseButton: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
