import Keycloak from 'keycloak-js';
import { computed } from '@vue/composition-api';
import * as R from 'ramda';
import Auth from '@/modules/auth/api/auth';
import store from '@/app/store';
import router from '@/app/router';

let keycloak: any | null = null;
if (
    !R.isNil(process.env.VUE_APP_KEYCLOAK_URL) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_URL) &&
    !R.isNil(process.env.VUE_APP_KEYCLOAK_REALM) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_REALM) &&
    !R.isNil(process.env.VUE_APP_KEYCLOAK_CLIENT_ID) &&
    !R.isEmpty(process.env.VUE_APP_KEYCLOAK_CLIENT_ID)
) {
    keycloak = Keycloak({
        url: process.env.VUE_APP_KEYCLOAK_URL,
        realm: process.env.VUE_APP_KEYCLOAK_REALM || '',
        clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID || '',
    });
}

const keycloakService = {
    isEnabled: () => {
        return !R.isNil(keycloak);
    },
    isAuthenticated: () => {
        return keycloak.authenticated && !keycloak.isTokenExpired();
    },
    login: async (to?: string | null) => {
        const isGuest = computed(
            () => store.state.auth.user && !store.state.auth.user.organisationId && keycloakService.isEnabled(),
        );
        const isNotVerified = computed(
            () =>
                store.state.auth.user &&
                store.state.auth.user.organisationId &&
                !store.state.auth.user.isVerified &&
                keycloakService.isEnabled(),
        );
        return new Promise((resolve, reject) => {
            if (!R.isNil(keycloak)) {
                keycloak
                    .init({ onLoad: 'login-required', promiseType: 'native', checkLoginIframe: false })
                    .then(async (authenticated: any) => {
                        store.commit.auth.SET_KEYCLOAK_TOKEN(keycloak.idToken);

                        if (authenticated) {
                            await Auth.keycloakLogin(keycloak.subject, keycloak.idToken, keycloak.refreshToken)
                                .then(async () => {
                                    store.commit.auth.CLEAR_KEYCLOAK_TOKEN();
                                    const { data } = await Auth.user();
                                    store.commit.auth.SET_USER(data);
                                    if (isGuest.value) {
                                        router.push({ name: 'organization-registration' });
                                    } else if (isNotVerified.value) {
                                        router.push({ name: 'unverified-organization' });
                                    } else {
                                        store.dispatch.auth.loadFeatures();
                                        store.dispatch.dataModel.loadDomains();
                                        store.dispatch.notificationEngine.fetchNotifications();
                                    }
                                    if (to && data.organisationId && data.isVerified) {
                                        router.push({ name: to });
                                    }
                                    resolve(true);
                                })
                                .catch((err) => {
                                    if (err.response?.data?.message || err.response?.data?.statusCode === 403) {
                                        reject(
                                            new Error(
                                                err.response.data.statusCode === 403
                                                    ? 'The account is not activated'
                                                    : err.response.data.message,
                                            ),
                                        );
                                    }
                                    if (err.message) {
                                        reject(new Error(err.message));
                                    }
                                    reject(new Error('Failed to login using keycloak'));
                                });
                        }
                        resolve(true);
                    })
                    .catch(() => {
                        reject(new Error('Authenticated Failed'));
                    });
            }
        });
    },
    logout: async () => {
        if (!R.isNil(keycloak)) {
            keycloak.init({ onLoad: 'login-required' });
            store.commit.auth.CLEAR_KEYCLOAK_TOKEN();
            await keycloak.logout();
        }
    },
    register: async () => {
        if (!R.isNil(keycloak)) {
            keycloak.init({ onLoad: 'login-required', promiseType: 'native' });
            await keycloak.register();
        }
    },
};

export default keycloakService;
