













import { computed, defineComponent } from '@vue/composition-api';
import { SupportIcon } from '@vue-hero-icons/outline';

export default defineComponent({
    name: 'NotificationSuggestionIcon',
    components: {
        SupportIcon,
    },
    props: {
        eventType: {
            type: String,
            required: true,
        },
        dropdown: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const iconType = computed(() => {
            switch (props.eventType) {
                case 'concept.created':
                    return 'neutral';
                case 'concept.approved':
                    return 'success';
                case 'concept.rejected':
                    return 'fail';
                default:
                    return 'neutral';
            }
        });

        return {
            iconType,
        };
    },
});
