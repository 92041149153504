























































import { defineComponent, ref, computed, inject } from '@vue/composition-api';
import dayjs from 'dayjs';
import { DropdownMenu } from '@/app/components';
import { useAssetStatus } from '../composable/asset-status';

export default defineComponent({
    name: 'AssetOverview',
    components: { DropdownMenu },
    props: {
        asset: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const isFeatureEnabled: any = inject('isEnabled');
        const options = ref<{ name: string; action: any }[]>([]);
        if (isFeatureEnabled('asset.metadata')) {
            options.value.push({
                name: 'Edit',
                action: () => {
                    emit('edit', props.asset);
                },
            });
        }
        options.value.push({
            name: 'Delete',
            action: () => {
                emit('destroy', props.asset);
            },
        });

        const status = computed(() => props.asset?.status);
        const { label: assetStatusLabel, colour: statusClass } = useAssetStatus(status);

        return {
            dayjs,
            options,
            emit,
            statusClass,
            isFeatureEnabled,
            assetStatusLabel,
        };
    },
});
