import Vue from 'vue';
import Vuex from 'vuex';
import { createDirectStore } from 'direct-vuex';
import auth from '@/modules/auth/store';
import { modelModule } from '@/modules/data-checkin/store';
import { notificationModule } from '@/modules/notification/store';

Vue.use(Vuex);

const { store, rootActionContext, moduleActionContext } = createDirectStore({
    modules: {
        auth,
        notificationEngine: notificationModule,
        dataModel: modelModule,
    },
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions.
export { rootActionContext, moduleActionContext };
// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module 'vuex' {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface Store<S> {
        direct: AppStore;
    }
}
