import { AxiosRequestConfig } from 'axios';

const endpoint = '/api/data-checkin';

export default {
    create: (jobId: number, stepId: number) =>
        ({ method: 'POST', url: `${endpoint}/upload-query/job/${jobId}/step/${stepId}` } as AxiosRequestConfig),
    get: (jobId: number, stepId: number) =>
        ({ method: 'GET', url: `${endpoint}/upload-query/job/${jobId}/step/${stepId}` } as AxiosRequestConfig),
};
