import axios from 'axios';
import store from '@/app/store';

const baseURL = '/api/';

const secured = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const plain = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

secured.interceptors.request.use(async (config) => {
    const token = store.getters.auth.getKeycloakToken;

    if (token) {
        if (!config.headers) {
            // eslint-disable-next-line no-param-reassign
            config.headers = {};
        }
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

plain.interceptors.request.use(async (config) => {
    const token = store.getters.auth.getKeycloakToken;

    if (token) {
        if (!config.headers) {
            // eslint-disable-next-line no-param-reassign
            config.headers = {};
        }
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});
export { plain, secured };
