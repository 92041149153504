import { secured, plain } from '@/app/config/axios';

const resource = 'auth';

export default {
    login: (data: any) => plain.post(`${resource}/login`, data),
    register: (data: any) => plain.post(`${resource}/register`, data),
    user: () =>
        secured.get(`${resource}/user`, {
            headers: {
                'Cache-Control': 'no-cache',
            },
        }),
    updateUser: (data: any) => secured.put(`${resource}/user`, data),
    logout: () => secured.post(`${resource}/logout`),
    keycloakLogin: (userId: string | undefined, userToken: string | undefined, userRefreshToken: string | undefined) =>
        plain.post(`${resource}/keycloak-login`, {
            profile: userId,
            accessToken: userToken,
            refreshToken: userRefreshToken,
        }),
};
