





























import { defineComponent, computed } from '@vue/composition-api';
import DataModelTreeNode from './DataModelTreeNode.vue';

export default defineComponent({
    name: 'DataModelTree',
    components: { DataModelTreeNode },
    props: {
        data: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        isRootSelectable: {
            type: Boolean,
            default: true,
        },
        selections: {
            type: Array,
            default() {
                return [];
            },
        },
        selectable: {
            type: Boolean,
            default: true,
        },
        autoselectChildren: {
            type: Boolean,
            default: false,
        },
        nameField: {
            type: String,
            default: 'name',
        },
        isVisible: {
            type: Function,
            default: () => {
                return true;
            },
        },
        selectionKey: {
            type: String,
        },
    },
    setup(props: any, { emit }: { emit: any }) {
        const hasChildren = computed(
            () => 'children' in props.data && props.data.children && props.data.children.length > 0,
        );

        const selectionChanged = (paths: string[]) => {
            emit('change', paths);
        };

        return { hasChildren, selectionChanged };
    },
});
