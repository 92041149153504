




























































































































































































































import Draggable from 'vuedraggable';
import * as R from 'ramda';
import { defineComponent, ref, computed } from '@vue/composition-api';
import ConfirmButton from './ConfirmButton.vue';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'MultivalueEditor',
    model: {
        prop: 'items',
        event: 'update-items',
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        requiredFields: {
            type: [Array, Function],
            default: () => [],
        },
        sortable: {
            type: Boolean,
            default: false,
        },
        uniqueCombination: {
            type: Array,
            default: () => [],
        },
        theme: {
            type: String,
            default: 'primary',
        },
        canAdd: {
            type: Boolean,
        },
        canUpdate: {
            type: Boolean,
        },
    },
    components: { ConfirmButton, Draggable },
    setup(props, { emit, slots }) {
        const showAddForm = ref(false);
        const newItem = ref({});
        const addErrors: any[] = [];
        const itemIdInEdit = ref<number | null>(null);
        const hoveredItemId = ref<string | null>(null);
        const getCombination = (item: any) => {
            const combination = {};
            for (let f = 0; f < props.uniqueCombination.length; f++) {
                const field: string = props.uniqueCombination[f] as string;
                let value = null;
                if (S.has(field, item)) {
                    value = item[field];
                }
                combination[field] = value;
            }
            return combination;
        };
        const isItemCombinationValid = (itemToCheck: any, itemsToCheck: any[]) => {
            if (props.uniqueCombination.length === 0) {
                return true;
            }
            const combination = getCombination(itemToCheck);
            for (let i = 0; i < itemsToCheck.length; i++) {
                const item = itemsToCheck[i];
                if (JSON.stringify(combination) === JSON.stringify(getCombination(item))) {
                    return false;
                }
            }
            return true;
        };
        const itemInEdit = computed((): any | null => {
            if (R.isNil(itemIdInEdit.value)) {
                return null;
            }
            return props.items[itemIdInEdit.value];
        });
        const requiredFields = computed(() => {
            if (R.is(Function, props.requiredFields)) {
                const func = props.requiredFields as any;
                if (itemInEdit.value) {
                    return func(itemInEdit.value);
                }
                return func(newItem.value);
            }
            return props.requiredFields;
        });

        const canAddItem = computed(() => {
            if (!R.isNil(props.canAdd) && !props.canAdd) return { enabled: false, message: null };

            if (!isItemCombinationValid(newItem.value, props.items)) {
                return { enabled: false, message: 'Cannot add because it already exists' };
            }
            for (let f = 0; f < requiredFields.value.length; f++) {
                const fieldKey: string = requiredFields.value[f] as string;
                if (
                    !S.has(fieldKey, newItem.value) ||
                    R.isNil(newItem.value[fieldKey]) ||
                    R.isEmpty(newItem.value[fieldKey])
                ) {
                    return { enabled: false, message: 'Cannot add because mandatory fields are missing' };
                }
            }
            return { enabled: true, message: null };
        });

        const canUpdateItem = computed(() => {
            if (!R.isNil(props.canUpdate) && !props.canUpdate) return { enabled: false, message: null };

            if (itemInEdit.value === null) {
                return { enabled: false, message: 'No item selected to update' };
            }
            const itemsExcludingEditedItem = [...props.items];
            itemsExcludingEditedItem.splice(itemIdInEdit.value as number, 1);
            if (!isItemCombinationValid(itemInEdit.value, itemsExcludingEditedItem)) {
                return { enabled: false, message: 'Cannot add because it already exists' };
            }
            for (let f = 0; f < requiredFields.value.length; f++) {
                const fieldKey: string = requiredFields.value[f] as string;
                if (
                    !S.has(fieldKey, itemInEdit.value) ||
                    R.isNil(itemInEdit.value[fieldKey]) ||
                    R.isEmpty(itemInEdit.value[fieldKey])
                ) {
                    return { enabled: false, message: 'Cannot update because mandatory fields are missing' };
                }
            }
            return { enabled: true, message: null };
        });
        const inEditMode = computed(() => itemIdInEdit.value !== null || showAddForm.value);
        const isSortable = computed(() => props.sortable && !props.readonly && itemIdInEdit.value === null);
        const hasListIcon = computed(() => slots.listIcon);
        const addFirstSlotName = computed(() => (slots.addFirst ? 'addFirst' : 'addAnother'));
        const createSlotName = computed(() => (slots.create ? 'create' : 'edit'));
        const add = () => {
            let updatedItems: any[] = [];
            if (props.items) {
                updatedItems = [...props.items];
            }
            emit('update-items', [...updatedItems, newItem.value]);
            emit('change', [...updatedItems, newItem.value]);
            showAddForm.value = false;
            newItem.value = {};
        };
        const remove = (index: number) => {
            const copyOfItems = [...props.items];
            copyOfItems.splice(index, 1);
            emit('update-items', copyOfItems);
            emit('change', copyOfItems);
        };
        const sort = (event: { oldIndex: any; newIndex: any }) => {
            let copyOfItems = [...props.items];
            const { oldIndex, newIndex } = event;
            copyOfItems = R.move(oldIndex, newIndex, copyOfItems);
            emit('update-items', copyOfItems);
            emit('change', copyOfItems);
        };
        return {
            showAddForm,
            newItem,
            addErrors,
            canAddItem,
            canUpdateItem,
            inEditMode,
            itemIdInEdit,
            hoveredItemId,
            hasListIcon,
            addFirstSlotName,
            createSlotName,
            add,
            remove,
            sort,
            isSortable,
            emit,
        };
    },
});
