import { computed, ref } from '@vue/composition-api';

import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

// HTTP connection to the API
const httpLink = createHttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP,
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const client = new ApolloClient({
    link: httpLink,
    cache,
});

export function useQuery(
    query: any,
    variables?: any,
    options?: {
        errorPolicy?: 'none' | 'ignore' | 'all';
        fetchPolicy?: 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby' | undefined;
    },
) {
    const loading = ref<boolean>(true);
    const error = ref<any>();
    let onErrorCallback = (e: string) => {
        return e;
    };
    let onResultCallback = (e: string) => {
        return e;
    };
    const result = ref<any>();

    const onError = (callback: any) => {
        onErrorCallback = callback;
    };

    const onResult = (callback: any) => {
        onResultCallback = callback;
    };

    const refetch = () => {
        client
            .query({ query, variables, fetchPolicy: options?.fetchPolicy, errorPolicy: options?.errorPolicy })
            .then(({ data }: any) => {
                result.value = data;
                loading.value = false;
                onResultCallback(data);
            })
            .catch((e: any) => {
                onErrorCallback(e);
                error.value = e;
            });
    };

    // first time
    refetch();

    return { loading, error, result, refetch, onError, onResult };
}

export function useResult(result: any, defaultValue?: any, pick?: any) {
    return computed(() => {
        const { value } = result;
        if (value) {
            if (pick) {
                try {
                    return pick(value);
                } catch (e) {
                    console.error(e);
                }
            } else {
                const keys = Object.keys(value);
                if (keys.length === 1) {
                    return value[keys[0]];
                }
                return value;
            }
        }
        return defaultValue;
    });
}
