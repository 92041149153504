export const tokenScopes = [
    {
        name: 'retrieve',
        description: "Retrieve the data you have selected from the platform's API",
        checked: false,
    },

    // {
    //     name: 'analyze',
    //     description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
    //     checked: false,
    // },

    // {
    //     name: 'search',
    //     description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit.',
    //     checked: false,
    // },

    {
        name: 'upload',
        description: "Upload your data to the platform's API",
        checked: false,
    },
];
