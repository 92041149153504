








import { defineComponent } from '@vue/composition-api';
import BaseTabs from './base/BaseTabs.vue';

export default defineComponent({
    name: 'Tabs',
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        activeTab: {
            type: Number,
            required: true,
        },
    },
    components: { BaseTabs },
    setup(props, { emit }) {
        const tabClicked = (tabIndex: number) => {
            emit('click', tabIndex);
        };
        return { tabClicked };
    },
});
