


















import * as R from 'ramda';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import JsonView from './JsonView.vue';
import { useJsonObject } from '../composable';

export default defineComponent({
    name: 'JsonParser',
    model: {
        prop: 'selectedItems',
        event: 'updateSelectedItems',
    },
    components: {
        JsonView,
    },
    props: {
        json: {
            type: [Object, Array],
            required: false,
        },
        selectedItems: {
            type: Array,
            required: false,
        },
        convert: {
            type: Boolean,
            default: false,
        },
        highlight: {
            type: Boolean,
            default: true,
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        showLine: {
            type: Boolean,
            default: false,
        },
        disableSelectionOfMultipleObjectArrays: {
            type: Boolean,
            default: false,
        },
        focusPath: {
            type: String,
            default: null,
        },
        separator: {
            type: String,
            default: '.',
        },
        disabledPaths: {
            type: Array,
            default: () => [],
        },
        additionalData: {
            type: Object,
            default: () => {
                return {};
            },
        },
        jsonSize: {
            type: Number,
            default: 10,
        },
        emptyMessage: {
            type: String,
            default: '',
        },
    },
    setup(props, { emit }) {
        const responsePrefix = 'res';
        const objectArrays = new Map();

        const { typeMismatches, getSuperObject, addMissingFields } = useJsonObject();

        const getAllPaths = (obj: any, prefix: string, key: string): string[] => {
            if (obj instanceof Array) {
                const allPaths = [];
                if (key !== '') {
                    allPaths.push(prefix + key);
                }
                let newPrefix = prefix;
                if (!R.endsWith(props.separator, prefix)) {
                    newPrefix = `${prefix}${props.separator}`;
                }
                if (newPrefix === props.separator) {
                    newPrefix = '';
                }
                const p: any = getAllPaths(obj[0], newPrefix, `${key}[0]`);
                for (let j = 0; j < p.length; j += 1) {
                    allPaths.push(p[j]);
                }
                return allPaths;
            }
            if (obj instanceof Object) {
                const keys = Object.keys(obj);
                const allPaths = [];
                if (key !== '') {
                    allPaths.push(prefix + key);
                }
                for (let i = 0; i < keys.length; i += 1) {
                    let newPrefix = `${prefix + key}${props.separator}`;
                    if (newPrefix === props.separator) {
                        newPrefix = '';
                    }
                    const p = getAllPaths(obj[keys[i]], newPrefix, keys[i]);
                    for (let j = 0; j < p.length; j += 1) {
                        allPaths.push(p[j]);
                    }
                }
                return allPaths;
            }

            if (R.isEmpty(key)) return [];
            return [prefix + key];
        };

        const convertJSON = (res: any, path: string): any => {
            if (res instanceof Array) {
                if (res.length > 1) {
                    if (res[0] instanceof Object) {
                        const supersetObject: any = getSuperObject(res, {}, path, props.separator);
                        return convertJSON([supersetObject], `${path}[0]`);
                    }
                    const result = [res[0]];
                    return convertJSON(result, `${path}[0]`);
                }
                const convertedObject = convertJSON(res[0], `${path}[0]`);
                return [convertedObject];
            }
            if (res instanceof Object) {
                const keys = Object.keys(res);
                const convertedObject: any = {};
                for (let i = 0; i < keys.length; i += 1) {
                    convertedObject[keys[i]] = convertJSON(res[keys[i]], `${path}${props.separator}${keys[i]}`);
                }
                return convertedObject;
            }
            if (R.isNil(res) || (!R.is(String, res) && R.isEmpty(res))) {
                return null;
            }
            return typeof res;
        };

        /**
         * @param objectArray  The map object in which to store key to complex object key mapping.
         * @param res The json response object itself
         * @param prefix From which key prefix are we coming from
         * @param parentArrayKey The complext object parent key if we are coming from any
         */
        const markObjectArrays = (
            objectArray: Map<string, string>,
            res: any,
            prefix = responsePrefix,
            parentArrayKey?: string,
        ): any => {
            if (res instanceof Array) {
                for (let i = 0; i < res.length; i += 1) {
                    const newKey = `${prefix}[${i}]`;

                    if (res[i] instanceof Object) {
                        // add it to map if it is a complex object
                        objectArrays.set(prefix, prefix);
                        markObjectArrays(objectArrays, res[i], newKey, prefix);
                    } else {
                        markObjectArrays(objectArrays, res[i], newKey);
                    }
                }
            } else if (res instanceof Object) {
                const keys = Object.keys(res);

                if (!R.isEmpty(parentArrayKey)) {
                    // add it to map if it belongs to a complex object
                    objectArrays.set(prefix, parentArrayKey);
                }

                for (let i = 0; i < keys.length; i += 1) {
                    const objectKey = keys[i];
                    const newKey = `${prefix}.${objectKey}`;
                    markObjectArrays(objectArrays, res[objectKey], newKey, parentArrayKey);
                }
            } else if (!R.isEmpty(parentArrayKey)) {
                // add it to map if it belongs to a complex object
                objectArrays.set(prefix, parentArrayKey);
            }
        };

        const addAdditionalData = (obj: any) => {
            const objClone: any = R.clone<any>(obj);
            for (let a = 0; a < Object.keys(props.additionalData).length; a++) {
                const additionalKey: string = Object.keys(props.additionalData)[a];
                if (Array.isArray(objClone)) {
                    if (objClone.length > 0) {
                        for (let j = 0; j < objClone.length; j++) {
                            objClone[j][additionalKey] = props.additionalData[additionalKey];
                        }
                    } else {
                        for (let j = 0; j < props.jsonSize; j++) {
                            objClone[j] = { additionalKey: props.additionalData[additionalKey] };
                        }
                    }
                } else {
                    objClone[additionalKey] = props.additionalData[additionalKey];
                }
            }
            return objClone;
        };

        const data = computed(() => {
            if (R.isEmpty(props.json)) {
                return null;
            }
            const jsonObject = addAdditionalData(R.clone(props.json));

            if (props.convert) {
                return convertJSON(jsonObject, 'res');
            }
            return jsonObject;
        });

        // create complex object map if disabling of multiple
        // complex objects is selected
        if (props.disableSelectionOfMultipleObjectArrays) {
            markObjectArrays(objectArrays, data.value);
        }
        const allPaths = ref<string[]>(getAllPaths(data.value, '', ''));

        const getParentNodes = (node: string): string[] => {
            const parts = node.split(props.separator);
            const parentNodes = [];
            if (node.slice(-3) === '[0]') {
                parentNodes.push(node.slice(0, -3));
            }
            for (let i = parts.length - 1; i > 0; i -= 1) {
                parts.pop();
                const parent = parts.join(props.separator);
                parentNodes.push(parent);
                if (parent.slice(-3) === '[0]') {
                    parentNodes.push(parent.slice(0, -3));
                }
            }
            return parentNodes;
        };

        const getChildrenNodes = (node: string): string[] => {
            const childrenNodes = [];
            for (let i = 0; i < allPaths.value.length; i += 1) {
                if (allPaths.value[i].startsWith(`${node}${props.separator}`)) {
                    childrenNodes.push(allPaths.value[i]);
                }
            }
            return childrenNodes;
        };

        const removeElement = (obj: any, element: string) => {
            if (obj === undefined) {
                return;
            }
            const parts = element.split(props.separator);
            if (parts.length === 1) {
                delete obj[element]; // eslint-disable-line no-param-reassign
                return;
            }
            let first = parts[0];
            parts.shift();
            const newElement = parts.join(props.separator);
            if (first.slice(-3) === '[0]') {
                first = first.slice(0, -3);
                let array: any[];
                if (first.length === 0) {
                    array = obj;
                } else {
                    array = obj[first];
                }
                if (typeof array === 'undefined') {
                    return;
                }
                for (let i = 0; i < array.length; i += 1) {
                    removeElement(array[i], newElement);
                }
            } else {
                removeElement(obj[first], newElement);
            }
        };

        const isObjectEmpty = (obj: any) => {
            return R.isNil(obj) || (!R.is(String, obj) && R.isEmpty(obj));
        };

        const dropEmpty = (obj: any): any =>
            R.type(obj) === 'Object' || R.type(obj) === 'Array' ? R.reject(isObjectEmpty, R.map(dropEmpty, obj)) : obj;

        // Removes items with type mismatches from selected items
        const filterSelectedItems = (items: any) => {
            const selItems = typeof items === 'string' ? [items] : items;
            const filteredItems: string[] = [];
            selItems.forEach((item: string) => {
                let foundTypeMismatch = false;
                typeMismatches.value.forEach((mismatch: string) => {
                    if (item.includes(mismatch)) {
                        foundTypeMismatch = true;
                    }
                });
                if (!foundTypeMismatch) {
                    filteredItems.push(item);
                }
            });
            return filteredItems;
        };

        const changeSelection = (items: any) => {
            // Resets currently selected complex object in case it was disselected
            const selItems = filterSelectedItems(items);
            emit('updateSelectedItems', selItems);
            const selected: string[] = [];
            let obj: any = [];
            if (selItems.length > 0) {
                obj = R.clone(props.json);
                for (let i = 0; i < selItems.length; i += 1) {
                    if (R.startsWith(`${responsePrefix}${props.separator}`, selItems[i])) {
                        selected.push(R.replace(`${responsePrefix}${props.separator}`, '', selItems[i]));
                    } else {
                        selected.push(R.replace(`${responsePrefix}`, '', selItems[i]));
                    }
                }
                const remaining = new Set();
                const all = allPaths.value;

                for (let i = 0; i < selected.length; i += 1) {
                    remaining.add(selected[i]);
                    const parentNodes = getParentNodes(selected[i]);
                    for (let j = 0; j < parentNodes.length; j += 1) {
                        remaining.add(parentNodes[j]);
                    }
                    const childrenNodes = getChildrenNodes(selected[i]);
                    for (let j = 0; j < childrenNodes.length; j += 1) {
                        remaining.add(childrenNodes[j]);
                    }
                }

                const removing = all.filter((node: string) => !remaining.has(node));
                typeMismatches.value.forEach((typeMismatch: string) => {
                    if (R.startsWith(`${responsePrefix}${props.separator}`, typeMismatch)) {
                        removing.push(R.replace(`${responsePrefix}${props.separator}`, '', typeMismatch));
                    } else {
                        removing.push(R.replace(`${responsePrefix}`, '', typeMismatch));
                    }
                });
                for (let i = 0; i < removing.length; i += 1) {
                    removeElement(obj, removing[i]);
                }
            }
            const addObj = dropEmpty(addAdditionalData(obj));
            const superObj = getSuperObject(R.clone(addObj), {});
            emit('selected-data-changed', addMissingFields(R.clone(addObj), superObj));
        };

        if (props.selectedItems !== undefined) {
            changeSelection(props.selectedItems);
        }

        watch(
            () => props.additionalData,
            () => changeSelection(props.selectedItems || []),
        );

        return { changeSelection, data, typeMismatches };
    },
});
