














































import * as R from 'ramda';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ButtonGroup',
    model: {
        prop: 'activeItem',
        event: 'click',
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        activeItem: {
            type: String,
            required: false,
        },
        activeItems: {
            type: Array,
            required: false,
            default: () => [],
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        whiteBackground: {
            type: Boolean,
            default: true,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const { is } = R;
        const getItemLabel = (item: any) => {
            return is(Object, item) ? item.label : item;
        };
        const getItemTooltip = (item: any) => {
            return is(Object, item) ? item.tooltip : '';
        };
        return { getItemLabel, getItemTooltip };
    },
});
