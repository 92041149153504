import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';

export const AuthenticateUser = async () => {
    // If the user is authenticated, continue with the route
    if (store.getters.auth.isAuthenticated) {
        return true;
    }

    try {
        // If not, try to get user (auto-login, if there's a valid cookie)
        const { data: user } = await Auth.user();
        store.commit.auth.SET_USER(user);
        return true;
    } catch (e) {
        // Otherwise, redirect log in
        return false;
    }
};
