
















































































































































































































































































































































































































import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { defineComponent, ref, computed } from '@vue/composition-api';
import Draggable from 'vuedraggable';
import { ConfirmButton } from '@/app/components';
import { S } from '@/app/utilities';

// Default functions
const defaultUpdateConditions = (query: any, newConditions: any[], replace = false) => {
    for (let c = 0; c < newConditions.length; c++) {
        const condition = newConditions[c];
        condition.conditionUid =
            S.has('conditionUid', condition) && !R.isNil(condition.conditionUid) ? condition.conditionUid : uuidv4();
    }
    if (replace) {
        return {
            ...query,
            conditions: newConditions,
        };
    }
    return {
        ...query,
        conditions: [...query.conditions, ...newConditions],
    };
};

const defaultUpdateGroups = (query: any, newGroups: any[]) => {
    return {
        ...query,
        conditions: [...query.conditions, ...newGroups],
    };
};

const defaultThemeCalculator = (query: any) => {
    switch (query.operant) {
        case 'AND':
            return 'purple';
        case 'OR':
            return 'teal';
        default:
            return null;
    }
};

export default defineComponent({
    name: 'QueryBuilder',
    model: {
        prop: 'query',
        event: 'update-query',
    },
    props: {
        identifier: {
            type: String,
            default: () => `query_builder_${uuidv4()}`,
        },
        query: {
            type: Object,
            required: true,
        },
        newConditionTemplate: {
            type: Object,
            default: () => {
                return {
                    concept: null,
                    operant: null,
                    value: null,
                };
            },
        },
        newGroupTemplate: {
            type: Object,
            default: () => {
                return {
                    operant: 'AND',
                    conditions: [],
                };
            },
        },
        isRoot: {
            type: Boolean,
            default: true,
        },
        isSortable: {
            type: Boolean,
            default: true,
        },
        conditionsExtractor: {
            type: Function,
            default: (query: any) => {
                return S.has('conditions', query) ? query.conditions : [];
            },
        },
        parentTheme: {
            type: String,
            default: 'neutral',
        },
        getUpdateConditionIssues: {
            type: Function,
            default: () => [],
        },
        updateConditions: {
            type: Function,
            default: defaultUpdateConditions,
        },
        updateGroups: {
            type: Function,
            default: defaultUpdateGroups,
        },
        themeCalculator: {
            type: Function,
            default: defaultThemeCalculator,
        },
        preprocessCondition: {
            type: Function,
            default: (condition: any) => {
                return condition;
            },
        },
        isConditionCalculator: {
            type: Function,
            default: (condition: any, template: any) => {
                for (let i = 0; i < Object.keys(template).length; i++) {
                    const key = Object.keys(template)[i];
                    if (!S.has(key, condition) || R.isNil(condition[key])) {
                        return false;
                    }
                }
                return true;
            },
        },
        isGroupCalculator: {
            type: Function,
            default: (group: any, template: any) => {
                for (let i = 0; i < Object.keys(template).length; i++) {
                    const key = Object.keys(template)[i];
                    if (!S.has(key, group) || R.isNil(group[key])) {
                        return false;
                    }
                }
                return true;
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: { Draggable, ConfirmButton },
    setup(props: any, { emit, root }: { emit: any; root: any }) {
        const isInCreate = ref<boolean>(false);
        const isInEdit = ref<boolean>(false);
        const isInHover = ref<boolean>(false);

        const newCondition = ref<any>(R.clone(props.newConditionTemplate));
        const queryInEdit = ref<any>(null);

        const conditions = computed(() => props.conditionsExtractor(props.query));

        const isCondition = computed(() => props.isConditionCalculator(props.query, props.newConditionTemplate));

        const isGroup = computed(() => props.isGroupCalculator(props.query, props.newGroupTemplate));

        const isInView = computed(() => !isInCreate.value && !isInEdit.value);

        const theme = computed((): string => {
            const calculatedTheme = props.themeCalculator(props.query);

            return R.isNil(calculatedTheme) ? props.parentTheme : calculatedTheme;
        });

        // Functions
        const emitUpdateQuery = (query: any) => {
            emit('update-query', query);
            emit('change', query);
        };

        const clear = () => {
            newCondition.value = R.clone(props.newConditionTemplate);
            isInCreate.value = false;
            isInEdit.value = false;
            queryInEdit.value = null;
        };

        const saveNewCondition = () => {
            const processedCondition = props.preprocessCondition(R.clone(newCondition.value));

            const updatedQuery = props.updateConditions(R.clone(props.query), [processedCondition]);
            const conditionErrors = props.getUpdateConditionIssues(updatedQuery);
            if (conditionErrors.length === 0) {
                emitUpdateQuery(updatedQuery);
                clear();
            } else {
                for (let e = 0; e < conditionErrors.length; e++) {
                    const error = conditionErrors[e];
                    (root as any).$toastr.e(error, 'Validation error');
                }
            }
        };

        const saveExistingCondition = () => {
            const processedCondition = props.preprocessCondition(queryInEdit.value);

            emitUpdateQuery(R.clone(processedCondition));
            clear();
        };

        const saveNewGroup = () => {
            emitUpdateQuery(props.updateGroups(R.clone(props.query), [R.clone(props.newGroupTemplate)]));

            clear();
        };

        const updateQuery = (query: any, index: number) => {
            const newConditions: any[] = R.clone(conditions.value) as any[];
            newConditions[index] = query;

            const updatedQuery = props.updateConditions(R.clone(props.query), newConditions, true);
            const conditionErrors = props.getUpdateConditionIssues(updatedQuery);
            if (conditionErrors.length === 0) {
                emitUpdateQuery(updatedQuery);
                clear();
            } else {
                for (let e = 0; e < conditionErrors.length; e++) {
                    const error = conditionErrors[e];
                    (root as any).$toastr.e(error, 'Validation error');
                }
            }
        };

        const editCondition = () => {
            isInEdit.value = true;
            queryInEdit.value = R.clone(props.query);
        };

        const deleteCondition = () => {
            emit('delete-condition');
        };

        const deleteConditionAtIndex = (index: number) => {
            const newConditions: any[] = R.clone(conditions.value) as any[];
            newConditions.splice(index, 1);
            const updatedQuery = props.updateConditions(R.clone(props.query), newConditions, true);
            const conditionErrors = props.getUpdateConditionIssues(updatedQuery);
            if (conditionErrors.length === 0) {
                emitUpdateQuery(updatedQuery);
                clear();
            } else {
                for (let e = 0; e < conditionErrors.length; e++) {
                    const error = conditionErrors[e];
                    (root as any).$toastr.e(error, 'Validation error');
                }
            }
        };

        const initializeQueryBuilder = () => {
            emitUpdateQuery(R.clone(props.newGroupTemplate));
            isInCreate.value = true;
        };

        const dragChange = () => {
            const updatedQuery = props.updateConditions(R.clone(props.query), conditions.value, true);
            const conditionErrors = props.getUpdateConditionIssues(updatedQuery);

            if (conditionErrors.length === 0) {
                emitUpdateQuery(updatedQuery);
            } else {
                for (let e = 0; e < conditionErrors.length; e++) {
                    const error = conditionErrors[e];
                    (root as any).$toastr.e(error, 'Validation error');
                }
            }
        };

        const changeNewQuery = (updatedQuery: any) => {
            newCondition.value = updatedQuery;
        };

        const changeExistingQuery = (updatedQuery: any) => {
            queryInEdit.value = updatedQuery;
        };

        return {
            isInCreate,
            isInEdit,
            isInView,
            isInHover,
            newCondition,
            isCondition,
            isGroup,
            theme,
            clear,
            saveNewCondition,
            saveExistingCondition,
            saveNewGroup,
            updateQuery,
            initializeQueryBuilder,
            conditions,
            editCondition,
            deleteCondition,
            deleteConditionAtIndex,
            dragChange,
            queryInEdit,
            changeNewQuery,
            changeExistingQuery,
        };
    },
});
