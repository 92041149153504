

































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import * as R from 'ramda';
import { v4 as uuidv4 } from 'uuid';
import { InputErrorIcon, Scrollbar } from '@/app/components';
import OnClickOutside from './OnClickOutside.vue';
import { S } from '@/app/utilities';

export default defineComponent({
    name: 'TailwindSelect',
    model: {
        prop: 'selected',
        event: 'update-selected',
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        keyField: {
            type: String,
            default: 'label',
        },
        labelField: {
            type: String,
            default: 'label',
        },
        selected: {
            type: [Array, String, Number],
            default: null,
        },
        rounded: {
            type: String,
            default: 'rounded',
        },
        height: {
            type: String,
            default: 'h-8',
        },
        leadingLabel: {
            type: String,
            default: null,
        },
        leadingLabelWidth: {
            type: String,
            default: 'w-36',
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: null,
        },
        fullError: {
            type: Boolean,
            default: true,
        },
        errorColour: {
            type: String,
            default: 'text-red-700',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        expandInPlace: {
            type: Boolean,
            default: false,
        },
        noResultsMessage: {
            type: String,
        },
        maxSelectedValues: {
            type: Number,
            default: 0, // Zero maxSelectedValues means that if multiple input is selected, no limit to max selected values will be applied
        },
    },
    components: { OnClickOutside, InputErrorIcon, Scrollbar },
    setup(props, { emit }) {
        const isOpen = ref<boolean>(false);
        const selectRef = ref<any>(null);
        const selectedItems = computed(() => {
            // figure out the list of selected item full objects (not just their labels)
            const selections = [];
            for (let i = 0; i < props.items.length; i++) {
                const item: any = props.items[i];

                // if there is a selection and we are dealing with the multiple option
                // we match the item and add it to the list of selections
                if (
                    !R.isNil(props.selected) &&
                    ((props.multiple && (props.selected as any[]).includes(item[props.keyField])) ||
                        (!props.multiple && props.selected === item[props.keyField]))
                ) {
                    selections.push(item);
                }
            }

            return selections;
        });
        const selectedItemsLabels = computed(() => {
            const selections = [];
            for (let i = 0; i < props.items.length; i++) {
                const item: any = props.items[i];
                if (
                    !R.isNil(props.selected) &&
                    ((props.multiple && (props.selected as any[]).includes(item[props.keyField])) ||
                        (!props.multiple && props.selected === item[props.keyField]))
                ) {
                    selections.push(item[props.labelField]);
                }
            }

            return selections.length > 0 ? selections.join(', ') : null;
        });

        const close = () => {
            isOpen.value = false;
        };

        const selection = (item: any) => {
            let selectedList;
            if (props.multiple) {
                if (!R.isNil(props.selected) && (props.selected as any[]).includes(item[props.keyField])) {
                    const updatedList = [...(props.selected as any[])];
                    updatedList.splice((props.selected as any[]).indexOf(item[props.keyField]), 1);
                    emit('update-selected', updatedList);
                    emit('change', updatedList);
                } else if (
                    !props.maxSelectedValues ||
                    (props.maxSelectedValues && (props.selected as any[]).length < props.maxSelectedValues)
                ) {
                    // If there is an input for maxSelectedValues, then limit will be applied
                    const updatedList = [
                        ...(!R.isNil(props.selected as any[]) ? (props.selected as any[]) : []),
                        item[props.keyField],
                    ];
                    emit('update-selected', updatedList);
                    emit('change', updatedList);
                } else if (props.maxSelectedValues) {
                    const updatedList = [...(props.selected as any[])];
                    updatedList.splice(0, 1, item[props.keyField]);
                    emit('update-selected', updatedList);
                    emit('change', updatedList);
                }
            } else {
                selectedList =
                    R.isNil(props.selected) || props.selected !== item[props.keyField] ? item[props.keyField] : null;
                emit('update-selected', selectedList);
                emit('change', item[props.keyField]);
            }
            if (!props.multiple || props.maxSelectedValues === 1) {
                close();
            }
        };

        const errorsString = computed(() => {
            const errorStrings = [];
            if (!props.errors || props.errors.length === 0) {
                return null;
            }
            if (props.errors.length === 1) {
                return props.errors[0];
            }
            for (let e = 0; e < props.errors.length; e++) {
                const error = props.errors[e];
                errorStrings.push(`<li>${error}</li>`);
            }
            return `<ul>${errorStrings.join('')}</ul>`;
        });

        const isSelectable = (item: any) => {
            if (S.has('selectable', item) && item.selectable === false) {
                return false;
            }
            return true;
        };

        const isSelected = (key: string) => {
            if (props.selected && ['String', 'Number'].includes(R.type(props.selected))) {
                return props.selected === key;
            }

            if (props.selected && R.is(Array, props.selected)) {
                return (props.selected as any[]).includes(key);
            }
            return false;
        };

        const changeSelection = (item: any) => {
            if (isSelectable(item) && !props.disabled) {
                selection(item);
            }
        };

        return {
            selectRef,
            selectedItems,
            selectedItemsLabels,
            errorsString,
            isOpen,
            close,
            uuidv4,
            selection,
            isSelected,
            isSelectable,
            changeSelection,
        };
    },
});
