import * as R from 'ramda';
import { Route, NavigationGuard } from 'vue-router';
import store from '@/app/store';
import { UserRoles } from '@/app/constants';

type Next = Parameters<NavigationGuard>[2];

export const RoleGuard = async (to: Route, from: Route, next: Next, role: UserRoles) => {
    const { user } = store.state.auth;
    if (user && user.roles && user.roles.length > 0) {
        if (R.includes(role, user.roles)) {
            return next();
        }
    }
    return next({ name: 'home' });
};
