import * as R from 'ramda';
import { SseQueue } from '../constants';
import { SseMessage } from '../interfaces';

export function useSSE() {
    let es: EventSource | null;

    const destroy = () => {
        if (es) {
            es.close();
            es = null;
        }
    };

    const initialise = (url: string, queue: SseQueue, onMessage: any, onError: any | null = null) => {
        if (es) destroy();
        es = new EventSource(url, { withCredentials: true });

        es.addEventListener('message', (event: MessageEvent) => {
            if (R.isNil(event) || R.isNil(event.data)) return;
            const { message, queue: messageQueue } = JSON.parse(event.data) as SseMessage;
            if (messageQueue === queue) {
                onMessage(message);
            }
        });

        es.addEventListener('error', (event: any) => {
            if (onError) onError(event);
        });
    };

    return { initialise, destroy };
}
