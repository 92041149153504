
































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'VerticalFormBlock',
    props: {
        title: { type: String, required: false },
        description: {
            type: String,
            required: false,
        },
        value: {
            type: [String, Array],
            required: true,
        },
        tags: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
});
