var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"\n        relative\n        inline-flex\n        items-center\n        justify-center\n        flex-shrink-0\n        w-10\n        h-5\n        rounded-full\n        group\n        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500\n    ",class:{ 'cursor-pointer': !_vm.disabled, ' cursor-not-allowed': _vm.disabled },attrs:{"type":"button","aria-pressed":"false","disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.change.apply(null, arguments)}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Use setting")]),_c('span',{staticClass:"absolute w-full h-full rounded-md pointer-events-none",class:_vm.backgroundColour,attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"absolute h-4 mx-auto transition-colors duration-200 ease-in-out rounded-full pointer-events-none w-9",class:[
            {
                'cursor-pointer': !_vm.disabled,
                'bg-primary-400': _vm.checked && _vm.checkedColour === 'primary',
                'bg-neutral-400': _vm.checked && _vm.checkedColour === 'neutral',
                'bg-secondary-400': _vm.checked && _vm.checkedColour === 'secondary',
                'bg-green-400': _vm.checked && _vm.checkedColour === 'green',
                'bg-red-400': _vm.checked && _vm.checkedColour === 'red',
                'bg-orange-400': _vm.checked && _vm.checkedColour === 'orange',
            },
            _vm.additionalInputClasses,
            !_vm.checked ? _vm.uncheckedColour : '' ],attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"\n            absolute\n            left-0\n            inline-block\n            w-5\n            h-5\n            transition-transform\n            duration-200\n            ease-in-out\n            transform\n            translate-x-0\n            bg-white\n            border\n            rounded-full\n            shadow\n            pointer-events-none\n            border-neutral-200\n            ring-0\n        ",class:{
            'translate-x-5': _vm.checked,
            'translate-x-0': !_vm.checked,
            'bg-neutral-400  border-neutral-400': _vm.disabled,
        },attrs:{"aria-hidden":"true"}})])}
var staticRenderFns = []

export { render, staticRenderFns }