import { NavigationGuard, Route } from 'vue-router';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import Keycloak from '@/modules/auth/api/keycloak';

type Next = Parameters<NavigationGuard>[2];

export const AuthGuard = async (to: Route, from: Route, next: Next) => {
    if (store.getters.auth.isAuthenticated) {
        return next();
    }

    try {
        // If not, try to get user (auto-login, if there's a valid cookie)
        const { data: user } = await Auth.user();
        store.commit.auth.SET_USER(user);
        await store.dispatch.auth.loadFeatures();
        if (to.meta?.feature && !store.getters.auth.isEnabled(to.meta.feature)) {
            return next({ name: '404' });
        }
        return next();
    } catch (e) {
        if (Keycloak.isEnabled()) {
            return Keycloak.login(to.name);
        }
        // Otherwise, redirect log in
        return next({ name: 'login', params: { path: to.fullPath } });
    }
};
