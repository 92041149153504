




import Multiselect from 'vue-multiselect';
import { computed } from '@vue/composition-api';

export default {
    components: {
        Multiselect,
    },
    props: {
        context: {
            type: Object,
            required: true,
        },
    },
    setup(props: any) {
        const options = computed(() => props.context.options.map((s: { value: string }) => s.value));
        const addTag = (newTag: any) => {
            if (!props.context.model) {
                // eslint-disable-next-line no-param-reassign
                props.context.model = [];
            }
            props.context.options.push(newTag);
            props.context.model.push(newTag);
        };

        return { options, addTag };
    },
};
