

























































































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    name: 'ShortTypeBadge',
    props: {
        typeName: {
            type: String,
            required: true,
        },
    },
});
