



































import { defineComponent } from '@vue/composition-api';
import { ExclamationCircleIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@vue-hero-icons/solid';
import { AlertType } from '@/app/constants';

export default defineComponent({
    name: 'AlertBanner',
    props: {
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        closable: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: AlertType.Error,
            validator: (value: string) => (Object.values(AlertType) as string[]).includes(value),
        },
        icon: {
            type: Boolean,
            default: false,
        },
    },
    components: { ExclamationIcon, ExclamationCircleIcon, InformationCircleIcon, XIcon },
    setup(props: any, { emit }: { emit: any }) {
        const areaClasses = {
            error: 'text-danger-700 bg-danger-100 border-danger-500',
            info: 'text-info-700 bg-info-100 border-info-500',
            warn: 'text-warn-700 bg-warn-100 border-warn-500',
        };
        const svgClasses = {
            error: 'text-danger-500',
            info: 'text-info-500',
            warn: 'text-warn-500',
        };

        const close = () => {
            emit('close');
        };
        return { AlertType, svgClasses, areaClasses, close };
    },
});
