/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import * as R from 'ramda';
import { ref } from '@vue/composition-api';

export function useJsonObject() {
    // The paths of the fields that have type mismatch used in json parser
    const typeMismatches = ref<string[]>([]);

    /**
     * Returns the super object of a json object or array which contains all the fields
     * @param record The json object or array
     * @param obj The super object of the json object or array
     * @param path (optional) The path of the field used in json parser
     * @param separator (optional) The separator used in the path (e.g. ||)
     */
    const getSuperObject = (record: any, obj: any, path = '', separator = '') => {
        // For objects
        if (R.type(record) === 'Object') {
            Object.keys(record).forEach((field: any) => {
                // For arrays of objects create super object inside an array
                if (R.type(obj) === 'Array') {
                    if (obj.length === 0) obj.push({});
                    obj = obj[0];
                }
                // If field does not exist in super object then add it
                if (!Object.keys(obj).includes(field)) obj[field] = record[field];
                // Add non null value to overwrite the existing one (to handle the case where the existing one is null or empty)
                else if (
                    record[field] !== null &&
                    (obj[field] === null ||
                        (R.type(obj[field]) === 'Array' && obj[field].length === 0) ||
                        (R.type(obj[field]) === 'Object' && Object.keys(obj[field]).length === 0))
                )
                    obj[field] = record[field];

                // If two records have different type (except Array type) then it is marked as a type mismatch
                if (record[field] !== null && obj[field] !== null && R.type(record[field]) !== R.type(obj[field]))
                    if (R.type(record[field]) === 'Array')
                        // If a record has Array type then set its value to the super object
                        obj[field] = record[field];
                    else if (R.type(obj[field]) !== 'Array')
                        typeMismatches.value.push(`${path}[0]${separator}${field}`);

                if (R.type(record[field]) === 'Object' || R.type(record[field]) === 'Array') {
                    let newObj = {}; // {} super object for objects
                    if (R.type(record[field]) === 'Array') newObj = []; // [] super object for arrays
                    if (obj[field] !== null) newObj = obj[field]; // value super object for all other types
                    getSuperObject(record[field], newObj, path, separator);
                    obj[field] = newObj;
                }
            });
        }
        // For arrays
        if (R.type(record) === 'Array') {
            record.forEach((object: any) => getSuperObject(object, obj, path, separator));
        }

        return obj;
    };

    /**
     * Adds missing fields to json irregular object or array
     * @param record The json irregular object or array
     * @param obj The super object of the json irregular object or array which contains all the fields
     */
    const addMissingFields = (record: any, obj: any) => {
        // If record is an Array
        if (R.type(record) === 'Array' && R.type(obj) === 'Object') {
            if (record.length === 0 && Object.keys(obj).length > 0) {
                record.push({});
            }
            record.forEach((object: any, index: number) => {
                record[index] = addMissingFields(object, obj);
            });
        }
        // If record is an Object
        if (R.type(record) === 'Object') {
            const keys = Object.keys(obj);
            keys.forEach((key: string) => {
                // If super object's key is not in the record then add it
                if (!Object.keys(record).includes(key)) {
                    if (R.type(obj[key]) === 'Object') {
                        record[key] = {}; // Add {} for objects
                    } else if (R.type(obj[key]) === 'Array') {
                        record[key] = []; // Add [] for arrays
                    } else {
                        record[key] = null; // Add null for all other types
                    }
                }
                // If record's value is an Object
                if (R.type(record[key]) === 'Object') {
                    record[key] = addMissingFields(record[key], obj[key]);
                }
                // If record's value is an Array
                if (
                    R.type(record[key]) === 'Array' &&
                    R.type(obj[key]) === 'Array' &&
                    obj[key].length > 0 &&
                    R.type(obj[key][0]) === 'Object'
                ) {
                    record[key] = addMissingFields(record[key], obj[key][0]);
                }
            });
        }
        return record;
    };

    /**
     * Converts to array any record that has type Array in an occurrence in the json object/array
     * @param record The json object or array
     * @param obj The super object of the json object or array which contains all the fields
     */
    const convertToArray = (record: any, obj: any) => {
        // If value in super object is an Array but record is not then make it an Array
        if (R.type(obj) === 'Array' && R.type(record) !== 'Array') {
            if (record) {
                record = [record];
            } else {
                record = [];
            }
        }
        // If record is an Array
        if (R.type(record) === 'Array' && R.type(obj) === 'Object') {
            record.forEach((object: any, index: number) => {
                record[index] = convertToArray(object, obj);
            });
        }
        // If record is an Object
        if (R.type(record) === 'Object') {
            const keys = Object.keys(obj);
            keys.forEach((key: string) => {
                // If super object's value is an Array but record's value is not then make it an Array
                if (R.type(obj[key]) === 'Array' && R.type(record[key]) !== 'Array') {
                    if (record[key]) {
                        record[key] = [record[key]];
                    } else {
                        record[key] = [];
                    }
                }
                // If record's value is an Object
                if (R.type(record[key]) === 'Object') {
                    record[key] = convertToArray(record[key], obj[key]);
                }
                // If record's value is an Array
                if (
                    R.type(record[key]) === 'Array' &&
                    R.type(obj[key]) === 'Array' &&
                    obj[key].length > 0 &&
                    R.type(obj[key][0]) === 'Object'
                ) {
                    record[key] = convertToArray(record[key], obj[key][0]);
                }
            });
        }
        return record;
    };

    /**
     * Finds the super object of a json object or array, converts to array any record that has type Array
     * in an occurrence, adds missing fields and returns the fixed json object or array
     * @param json The json object or array
     */
    const getFixedJSON = (json: any) => {
        const superObj = getSuperObject(R.clone(json), {});
        const fixedJSON = convertToArray(R.clone(json), superObj);
        return addMissingFields(R.clone(fixedJSON), superObj);
    };

    return {
        getSuperObject,
        addMissingFields,
        convertToArray,
        typeMismatches,
        getFixedJSON,
    };
}
