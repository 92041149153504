



























































import { defineComponent, ref, watch, computed } from '@vue/composition-api';
import { InputErrorIcon } from '@/app/components';

export default defineComponent({
    name: 'TailwindInput',
    model: {
        prop: 'value',
        event: 'update-value',
    },
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        id: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
        },
        type: {
            type: String,
            default: 'text',
        },
        leadingLabel: {
            type: String,
            default: null,
        },
        leadingLabelWidth: {
            type: String,
            default: 'w-36',
        },
        prefix: {
            type: String,
            default: null,
        },
        suffix: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        errors: {
            type: Array,
            default: null,
        },
        fullError: {
            type: Boolean,
            default: true,
        },
        errorColour: {
            type: String,
            default: 'text-red-700',
        },
        additionalInputClasses: {
            type: String,
        },
        step: {
            type: Number,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        preprocess: {
            type: Function,
            default: (val: any) => {
                return val;
            },
        },
    },
    components: { InputErrorIcon },
    setup(props, { emit }) {
        const valueRef = ref<any>(props.value);

        const errorsString = computed(() => {
            const errorStrings = [];
            if (!props.errors || props.errors.length === 0) {
                return null;
            }
            if (props.errors.length === 1) {
                return props.errors[0];
            }
            for (let e = 0; e < props.errors.length; e++) {
                const error = props.errors[e];
                errorStrings.push(`<li>${error}</li>`);
            }
            return `<ul>${errorStrings.join('')}</ul>`;
        });

        const change = () => {
            const newValue = (props.preprocess as any)(valueRef.value);
            emit('update-value', newValue);
            emit('change', newValue);
        };

        const enter = () => {
            emit('enter', valueRef.value);
        };

        watch(
            () => props.value,
            (updatedValue: any) => {
                valueRef.value = updatedValue;
            },
        );
        return { errorsString, valueRef, change, enter };
    },
});
