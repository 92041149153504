


















import { defineComponent } from '@vue/composition-api';
import SvgImage from '@/app/components/SvgImage.vue';

export default defineComponent({
    name: 'Error503',
    components: { SvgImage },
});
