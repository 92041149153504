import { render, staticRenderFns } from "./Dashboard.page1.vue?vue&type=template&id=45254ebf&scoped=true&"
import script from "./Dashboard.page1.vue?vue&type=script&lang=ts&"
export * from "./Dashboard.page1.vue?vue&type=script&lang=ts&"
import style0 from "./Dashboard.page1.vue?vue&type=style&index=0&id=45254ebf&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45254ebf",
  null
  
)

export default component.exports