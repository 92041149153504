import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import numeral from 'numeral';

dayjs.extend(relativeTime);

export function useFilters() {
    const formatBytes = (value: number) => numeral(value).format('0.0 b');
    const splitCamelCase = (value: string) => value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
    const splitLowerCase = (value: string) => {
        return splitCamelCase(value).toLowerCase();
    };
    const fromNow = (value: Date | string) => dayjs(value).fromNow();
    const formatNumber = (value: number) => {
        if (!Number.isInteger(value)) return value.toFixed(2);
        return value;
    };
    const countDecimals = (value: number) => {
        if (Math.floor(value) === value) return 0;
        return value.toString().split('.')[1].length || 0;
    };
    const formatDate = (value: string) => {
        return Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'long', year: 'numeric' }).format(
            Date.parse(value),
        );
    };
    const formatDateTime = (value: string) => {
        return Intl.DateTimeFormat('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: 'numeric',
            timeZoneName: 'short',
            hour12: false,
        }).format(Date.parse(value));
    };

    return {
        countDecimals,
        formatBytes,
        formatDate,
        formatDateTime,
        formatNumber,
        fromNow,
        splitCamelCase,
        splitLowerCase,
    };
}
