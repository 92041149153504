import { Route, NavigationGuard } from 'vue-router';
import store from '@/app/store';

type Next = Parameters<NavigationGuard>[2];

export const GuestOnly = (to: Route, from: Route, next: Next) => {
    // If the user is not authenticated, continue with the route
    if (!store.getters.auth.isAuthenticated) {
        return next();
    }

    // Otherwise, redirect to home
    return next({ name: 'home' });
};
