import { AxiosRequestConfig } from 'axios';
import { ExternalKafkaConfig } from '../types';

const endpoint = '/api/kafka';

export default {
    resetPassword: (username: string, stepId?: number, jobId?: number) => {
        const queryParams = jobId ? `jobId=${jobId}` : `stepId=${stepId}`;
        return {
            method: 'PUT',
            url: `${endpoint}/users/${username}/resetPassword?${queryParams}`,
        } as AxiosRequestConfig;
    },
    subscribeToTopic: (topic: string, stepId: number) =>
        ({
            method: 'POST',
            url: `${endpoint}/topics/${topic}/subscribe?stepId=${stepId}`,
        } as AxiosRequestConfig),
    getTopicConnectionDetails: (topic: string, stepId: number) =>
        ({
            method: 'GET',
            url: `${endpoint}/topics/${topic}/connection-details?stepId=${stepId}`,
        } as AxiosRequestConfig),
    testCredentialsAndCreateSample: (data: ExternalKafkaConfig, fileType: string) =>
        ({
            method: 'POST',
            url: `${endpoint}/user/${data.topic}/consume/${fileType}`,
            data,
        } as AxiosRequestConfig),
};
