export enum StatusCode {
    Uploading = 'uploading', // before the availability of data
    Incomplete = 'incomplete', // with data before completion of metadata
    Internal = 'internal', // with data and metadata complete - available internally
    Pending = 'pending', // pending derivation contracts
    Available = 'available', // available to marketplace
    Updating = 'updating', // in updating process
    Deprecated = 'deprecated',
    Archived = 'archived',
    Draft = 'draft',
}
